import Extractor from "@jordibosch20/software_tools_package/dist/pages/Extractor.component.js";
import styles from "./App.module.css";
import { getEmail } from "@jordibosch20/software_tools_package/dist/utils/email-extractor";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <Extractor title={"Email extractor"} f={getEmail}></Extractor>
      </div>
      <div className={styles.text}>
        <p><strong> Introduction</strong>: In today's digital landscape, effective communication and data management are key to success. Whether you're building a marketing campaign, sourcing candidates for recruitment, or conducting market research, the ability to quickly and accurately extract email addresses from large volumes of text is invaluable. Our Bulk Email Extractor is engineered to meet these needs, offering a robust solution that simplifies email collection. With just a few clicks, you can transform unstructured text into a structured list of email addresses, ready for use in your projects.</p>
        <p><strong>Why Our Bulk Email Extractor Stands Out</strong>:</p>
        <ul>
        <li><strong>High-Speed Extraction</strong>: Process large texts in seconds, ensuring no email address is overlooked.</li>
        <li><strong>Accuracy and Reliability</strong>: Utilize advanced algorithms to accurately identify and extract valid email addresses from mixed content.</li>
        <li><strong>Ease of Use</strong>: Benefit from a straightforward interface that makes bulk email extraction accessible to users of all skill levels.</li>
        <li><strong>Versatile Functionality</strong>: Perfect for various professional uses, from digital marketing campaigns to academic research and recruitment efforts.</li>
        <li><strong>Data Privacy</strong>: Prioritize the security and privacy of your data, with a commitment to ethical email extraction practices.</li>
        </ul>
        <p><strong>How It Works</strong>:</p>
        <ol>
        <li><strong>Input Your Text</strong>: Copy and paste the text containing the email addresses into our tool.</li>
        <li><strong>Initiate Extraction</strong>: Click the extract button to start the process. Our tool scans the text, identifying and extracting email addresses.</li>
        <li><strong>Collect and Utilize</strong>: Access a clean, organized list of email addresses ready for your marketing, research, or recruitment databases.</li>
        </ol>
        <p><strong>Applications Across Industries</strong>:</p>
        <ul>
        <li><strong>Digital Marketing</strong>: Build comprehensive email lists for newsletter subscriptions, promotional campaigns, or customer outreach.</li>
        <li><strong>Recruitment</strong>: Quickly gather contact information of potential candidates from various sources.</li>
        <li><strong>Market Research</strong>: Compile email addresses for surveys, studies, or feedback collection.</li>
        <li><strong>Event Organizing</strong>: Efficiently collect attendee emails for invitations, updates, and follow-ups.</li>
        </ul>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;